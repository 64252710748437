import React, { Component, forwardRef } from 'react';
import SEO from '../../services/SEO';
import { NavLink } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { parseIncompletePhoneNumber } from 'libphonenumber-js';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from 'moment';

import { sendDemoRequest } from '../../store/actions';
import requestDemoImg from '../../assets/images/request_demo_img.png';
import Input from '../../UI/Input/Input';
import CPselect from '../../UI/CPselect/CPselect';
import Textarea from '../../UI/Textarea/Textarea';
import TimePicker from '../../UI/TimePicker/TimePicker';
import Button from '../../UI/Button/Button';
import {DEMO_VALIDATION} from '../../services/validation';
import utils from '../../services/utils';
import './RequestDemoStyles.css';

const pageProps = {
    title: "Demo - Control Point | Best student dismissal software and most effective afternoon dismissal application",
    description: "See Control Point in action! Request Control Point demo today and get free 2 weeks test for your school and see how effective can be your afternoon dismissal process | Feedback heard most often is how efficient afternoon dismissal has become since incorporating Control Point",
    url: "https://controlpointdismissal.com/request-demo",
    robots: "index, follow"
}

const INITIAL_STATE = {
    fields: {
        FullName: '',
        CampusRoleId: '',
        SchoolName: '',
        SchoolAddress: '',
        SchoolDistrict: '',
        Email: '',
        Phone: '',
        BestContactDate: null,
        BestContactTime: '',
        Comment: ''
    },
    fieldsError: {},
    fieldsTouched: {}
};

const CustomInput = forwardRef(({ onClick, value }, ref) => (
    <input
        onClick={onClick}
        ref={ref}
        value={value}
        type="text"
        readOnly={true}
        placeholder="Date"
        />
));

const ref = React.createRef();
const calendarRef = React.createRef();

class RequestDemo extends Component {
    state = {
        ...INITIAL_STATE
    };

    componentDidMount() {
        window.scrollTo({top: 0});
    }

    checkButtonStatus = fields => {
        const fieldsArray = Object.keys(fields);
        let isDisabled = false;
        for(let i=0; i< fieldsArray.length; i++) {
            const fieldName = fieldsArray[i];
            if(DEMO_VALIDATION[fieldName] && DEMO_VALIDATION[fieldName].required && !fields[fieldName]) {
                isDisabled = true;
            }
        }

        return isDisabled;
    };

    handleFieldChange = (e, fieldName) => {
        const {fields, fieldsTouched, fieldsError} = this.state;
        const _fields = {...fields};
        const _fieldsTouched = {...fieldsTouched};
        let value = (fieldName === 'BestContactDate' || fieldName === 'BestContactTime') ? e : e.target.value;
        if(fieldName === 'CampusRoleId') {
            value = +value;
        }
        if(fieldName === 'Phone') {
            value = parseIncompletePhoneNumber(value);
        }
        _fieldsTouched[fieldName] = true;

        this.setState({fields: {..._fields, [fieldName]: value}, fieldsTouched: _fieldsTouched});
        utils.checkValidationErrors(fieldName, {...fields, [fieldName]: value}, _fieldsTouched, fieldsError, null, DEMO_VALIDATION, this);
    };

    handleBlur = (fieldName) => {
        const {fields, fieldsTouched, fieldsError} = this.state;
        const _fieldsTouched = {...fieldsTouched};
        _fieldsTouched[fieldName] = true;

        this.setState({fieldsTouched: _fieldsTouched});
        utils.checkValidationErrors(fieldName, fields, _fieldsTouched, fieldsError, true, DEMO_VALIDATION, this);
    };

    handleSubmit = () => {
        const {fields} = this.state;
        const _fields = {...fields};

        if(_fields.BestContactDate) {
            _fields.BestContactDate = moment(_fields.BestContactDate).format('YYYY-MM-DD');
        }
        _fields.BestContactTime = _fields.BestContactDate + 'T' + _fields.BestContactTime;
        delete _fields.BestContactDate;

        this.props.onSendDemoRequest(_fields)
            .then(success => {
                if(success) {
                    this.setState({...INITIAL_STATE});
                }
            });
    };

  render() {
      const {fields, fieldsTouched, fieldsError} = this.state;
      const {campusRoles, sendingDemoRequest} = this.props;
      const btnDisabled = Object.keys(fieldsError).length > 0 || this.checkButtonStatus(fields) || sendingDemoRequest;

      return (
           <>
              <SEO pageProps={pageProps} />
              <div className="page-wrapper">
              <section className="request-demo-section">
                  <div className="container">
                      <div className="row">
                              <div className="col-md-6 col-sm-12">
                              <h1 className="purple">Want to see Control Point in action?</h1>
                              <div className="request-demo-form">
                                  <div className="row">
                                      <div className="col-sm-10">
                                          <Input type="text"
                                                 onChange={e => this.handleFieldChange(e, 'FullName')}
                                                 onBlur={() => this.handleBlur('FullName')}
                                                 value={fields.FullName}
                                                 error={fieldsError.FullName}
                                                 placeholder="Full Name" />
                                      </div>
                                      <div className="col-sm-10">
                                          <CPselect onChange={e => this.handleFieldChange(e, 'CampusRoleId')}
                                                  onBlur={() => this.handleBlur('CampusRoleId')}
                                                  options={campusRoles}
                                                  name="Campus Role"
                                                  value={fields.CampusRoleId}
                                                  error={fieldsError.CampusRoleId} />
                                      </div>
                                      <div className="col-sm-10">
                                          <Input type="text"
                                                 onChange={e => this.handleFieldChange(e, 'SchoolName')}
                                                 onBlur={() => this.handleBlur('SchoolName')}
                                                 value={fields.SchoolName}
                                                 error={fieldsError.SchoolName}
                                                 placeholder="School Name" />
                                      </div>
                                      <div className="col-sm-10">
                                          <Input type="text"
                                                 onChange={e => this.handleFieldChange(e, 'SchoolAddress')}
                                                 onBlur={() => this.handleBlur('SchoolAddress')}
                                                 value={fields.SchoolAddress}
                                                 error={fieldsError.SchoolAddress}
                                                 placeholder="School Address" />
                                      </div>
                                      <div className="col-sm-10">
                                          <Input type="text"
                                                 onChange={e => this.handleFieldChange(e, 'SchoolDistrict')}
                                                 onBlur={() => this.handleBlur('SchoolDistrict')}
                                                 value={fields.SchoolDistrict}
                                                 error={fieldsError.SchoolDistrict}
                                                 placeholder="School District" />
                                      </div>
                                      <div className="col-sm-10">
                                          <Input type="email"
                                                 onChange={e => this.handleFieldChange(e, 'Email')}
                                                 onBlur={() => this.handleBlur('Email')}
                                                 value={fields.Email}
                                                 error={fieldsError.Email}
                                                 placeholder="Email" />
                                      </div>
                                      <div className="col-md-5 col-sm-10">
                                          <div className="date-picker">
                                              <DatePicker selected={fields.BestContactDate}
                                                          customInput={<CustomInput ref={ref} />}
                                                          onChange={e => this.handleFieldChange(e, 'BestContactDate')}
                                                          onCalendarClose={() => this.handleBlur('BestContactDate')}
                                                          showPopperArrow={false}
                                                          minDate={new Date()}
                                                          ref={calendarRef}
                                                          placeholderText="Date" />
                                              <span className="date-picker-arrow" onClick={() => calendarRef.current.setOpen(true)}></span>
                                              {fieldsError.BestContactDate ? <span className="date-picker-error">{fieldsError.BestContactDate}</span> : null}
                                          </div>
                                      </div>
                                      <div className="col-md-5 col-sm-10">
                                          <TimePicker onChange={e => this.handleFieldChange(e, 'BestContactTime')}
                                                      onBlur={() => this.handleBlur('BestContactTime')}
                                                      fieldsTouched={fieldsTouched.BestContactTime}
                                                      error={fieldsError.BestContactTime}
                                                      placeholder="Time" />
                                      </div>
                                      <div className="col-sm-10">
                                          <Input type="text"
                                                 onChange={e => this.handleFieldChange(e, 'Phone')}
                                                 onBlur={() => this.handleBlur('Phone')}
                                                 value={fields.Phone}
                                                 error={fieldsError.Phone}
                                                 placeholder="Phone" />
                                      </div>
                                  </div>
                                  <Textarea rows={5}
                                        onChange={e => this.handleFieldChange(e, 'Comment')}
                                        onBlur={() => this.handleBlur('Comment')}
                                        value={fields.Comment}
                                        error={fieldsError.Comment}
                                        placeholder="Comments ..."  />
                                  <Button title="Request demo" active onClick={this.handleSubmit} disabled={btnDisabled} />
                              </div>
                          </div>

                          <div className="col-md-5 col-sm-12 offset-md-1 offset-sm-0">
                              <div className="request-demo-btns-container">
                                  <NavLink strict to="/request-quote" className="cp-btn-link">Request quote</NavLink>
                                  <br />
                                  <NavLink strict to="/support" className="cp-btn-link">Request support</NavLink>
                              </div>
                              <div className="request-demo-image-container">
                                      <img src={requestDemoImg} alt="Contact Us" title="Contact Us" />
                              </div>
                          </div>
                      </div>
                  </div>
              </section>
              </div>
           </>
      );
  }
}

const mapStateToProps = state => {
    return {
        campusRoles: state.main.campusRoles,
        sendingDemoRequest: state.main.sendingDemoRequest
    };
};

const mapDispatchToProps = dispatch => {
    return {
        onSendDemoRequest: data => dispatch(sendDemoRequest(data))
    };
};

RequestDemo.propTypes = {
    campusRoles: PropTypes.array,
    sendingDemoRequest: PropTypes.bool,
    onSendDemoRequest: PropTypes.func
};

export default connect(mapStateToProps, mapDispatchToProps)(RequestDemo);
