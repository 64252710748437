import React, { Component } from 'react';
import SEO from '../../services/SEO';
import { NavLink } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { parseIncompletePhoneNumber } from 'libphonenumber-js';

import { sendQuoteRequest } from '../../store/actions';
import requestQuoteImg from '../../assets/images/request_quote_img.png';
import Input from '../../UI/Input/Input';
import InputQtyDropdown from '../../UI/InputQtyDropdown/InputQtyDropdown';
import CPselect from '../../UI/CPselect/CPselect';
import Textarea from '../../UI/Textarea/Textarea';
import Button from '../../UI/Button/Button';
import {QUOTE_VALIDATION} from '../../services/validation';
import utils from '../../services/utils';
import './RequestQuoteStyles.css';


const pageProps = {
    title: "Quote - Control Point | Best student dismissal software and most effective afternoon dismissal application",
    description: "Request a quote for Control Point today, plan your budget and see how effective can be your afternoon dismissal process | We offer flexible prices for your school needs | Feedback heard most often is how efficient afternoon dismissal has become since incorporating Control Point",
    url: "https://controlpointdismissal.com/request-quote",
    robots: "index, follow"
}
const INITIAL_STATE = {
    fields: {
        FullName: '',
        CampusRoleId: '',
        SchoolName: '',
        SchoolAddress: '',
        SchoolDistrict: '',
        Email: '',
        Phone: '',
        NumberOfStudents: '',
        NumberOfFaculty: '',
        Comment: ''
    },
    fieldsError: {},
    fieldsTouched: {}
};

class RequestQuote extends Component {
    state = {
        ...INITIAL_STATE
    };

    componentDidMount() {
        window.scrollTo({top: 0});
    }

    checkButtonStatus = fields => {
        const fieldsArray = Object.keys(fields);
        let isDisabled = false;
        for(let i=0; i< fieldsArray.length; i++) {
            const fieldName = fieldsArray[i];
            if(QUOTE_VALIDATION[fieldName] && QUOTE_VALIDATION[fieldName].required && !fields[fieldName]) {
                isDisabled = true;
            }
        }

        return isDisabled;
    };

    handleFieldChange = (e, fieldName) => {
        const {fields, fieldsTouched, fieldsError} = this.state;
        const _fields = {...fields};
        const _fieldsTouched = {...fieldsTouched};
        let value = e.target.value;
        if(fieldName === 'CampusRoleId') {
            value = +value;
        }
        if(fieldName === 'Phone') {
            value = parseIncompletePhoneNumber(value);
        }
        _fieldsTouched[fieldName] = true;

        this.setState({fields: {..._fields, [fieldName]: value}, fieldsTouched: _fieldsTouched});
        utils.checkValidationErrors(fieldName, {...fields, [fieldName]: value}, _fieldsTouched, fieldsError, null, QUOTE_VALIDATION, this);
    };

    handleBlur = (fieldName) => {
        const {fields, fieldsTouched, fieldsError} = this.state;
        const _fieldsTouched = {...fieldsTouched};
        _fieldsTouched[fieldName] = true;

        this.setState({fieldsTouched: _fieldsTouched});
        utils.checkValidationErrors(fieldName, fields, _fieldsTouched, fieldsError, true, QUOTE_VALIDATION, this);
    };

    handleSubmit = () => {
        const {fields} = this.state;

        this.props.onSendQuoteRequest(fields)
            .then(success => {
                if(success) {
                    this.setState({...INITIAL_STATE});
                }
            });
    };

  render() {
      const {fields, fieldsError} = this.state;
      const {campusRoles, sendingQuoteRequest} = this.props;
      const btnDisabled = Object.keys(fieldsError).length > 0 || this.checkButtonStatus(fields) || sendingQuoteRequest;

      return (
           <>
              <SEO pageProps={pageProps} />
              <div className="page-wrapper">
              <section className="request-quote-section">
                  <div className="container">
                      <div className="row">
                              <div className="col-md-6 col-sm-12">
                              <h1 className="purple">Learn more about Control Point price</h1>
                              <div className="request-quote-form">
                                  <div className="row">
                                      <div className="col-sm-10">
                                          <Input type="text"
                                                 onChange={e => this.handleFieldChange(e, 'FullName')}
                                                 onBlur={() => this.handleBlur('FullName')}
                                                 value={fields.FullName}
                                                 error={fieldsError.FullName}
                                                 placeholder="Full Name" />
                                      </div>
                                      <div className="col-sm-10">
                                          <CPselect onChange={e => this.handleFieldChange(e, 'CampusRoleId')}
                                                  onBlur={() => this.handleBlur('CampusRoleId')}
                                                  options={campusRoles}
                                                  name="Campus Role"
                                                  value={fields.CampusRoleId}
                                                  error={fieldsError.CampusRoleId} />
                                      </div>
                                      <div className="col-sm-10">
                                          <Input type="text"
                                                 onChange={e => this.handleFieldChange(e, 'SchoolName')}
                                                 onBlur={() => this.handleBlur('SchoolName')}
                                                 value={fields.SchoolName}
                                                 error={fieldsError.SchoolName}
                                                 placeholder="School Name" />
                                      </div>
                                      <div className="col-sm-10">
                                          <Input type="text"
                                                 onChange={e => this.handleFieldChange(e, 'SchoolAddress')}
                                                 onBlur={() => this.handleBlur('SchoolAddress')}
                                                 value={fields.SchoolAddress}
                                                 error={fieldsError.SchoolAddress}
                                                 placeholder="School Address" />
                                      </div>
                                      <div className="col-sm-10">
                                          <Input type="text"
                                                 onChange={e => this.handleFieldChange(e, 'SchoolDistrict')}
                                                 onBlur={() => this.handleBlur('SchoolDistrict')}
                                                 value={fields.SchoolDistrict}
                                                 error={fieldsError.SchoolDistrict}
                                                 placeholder="School District" />
                                      </div>
                                      <div className="col-sm-10">
                                          <Input type="email"
                                                 onChange={e => this.handleFieldChange(e, 'Email')}
                                                 onBlur={() => this.handleBlur('Email')}
                                                 value={fields.Email}
                                                 error={fieldsError.Email}
                                                 placeholder="Email" />
                                      </div>
                                      <div className="col-sm-10">
                                          <Input type="text"
                                                 onChange={e => this.handleFieldChange(e, 'Phone')}
                                                 onBlur={() => this.handleBlur('Phone')}
                                                 value={fields.Phone}
                                                 error={fieldsError.Phone}
                                                 placeholder="Phone" />
                                      </div>
                                      <div className="col-md-5 col-sm-10">
                                          <InputQtyDropdown type="text"
                                                            onChange={e => this.handleFieldChange(e, 'NumberOfStudents')}
                                                            onBlur={() => this.handleBlur('NumberOfStudents')}
                                                            min={0}
                                                            max={9999}
                                                            value={fields.NumberOfStudents}
                                                            error={fieldsError.NumberOfStudents}
                                                            placeholder="Number of students" />
                                      </div>
                                      <div className="col-md-5 col-sm-10">
                                          <InputQtyDropdown type="text"
                                                            onChange={e => this.handleFieldChange(e, 'NumberOfFaculty')}
                                                            onBlur={() => this.handleBlur('NumberOfFaculty')}
                                                            min={0}
                                                            max={100}
                                                            value={fields.NumberOfFaculty}
                                                            error={fieldsError.NumberOfFaculty}
                                                            placeholder="Number of faculty/staff" />
                                      </div>
                                  </div>
                                  <Textarea rows={5}
                                        onChange={e => this.handleFieldChange(e, 'Comment')}
                                        onBlur={() => this.handleBlur('Comment')}
                                        value={fields.Comment}
                                        error={fieldsError.Comment}
                                        placeholder="Comments ..."  />
                                  <Button title="Request quote" active onClick={this.handleSubmit} disabled={btnDisabled} />
                              </div>
                          </div>

                          <div className="col-md-5 col-sm-12 offset-md-1 offset-sm-0">
                              <div className="request-quote-btns-container">
                                  <NavLink strict to="/request-demo" className="cp-btn-link">Request demo</NavLink>
                                  <br />
                                  <NavLink strict to="/support" className="cp-btn-link">Request support</NavLink>
                              </div>
                              <div className="request-quote-image-container">
                                      <img src={requestQuoteImg} alt="Contact Us" title="Contact Us"  />
                              </div>
                          </div>
                      </div>
                  </div>
              </section>
              </div>
           </>
      );
  }
}

const mapStateToProps = state => {
    return {
        campusRoles: state.main.campusRoles,
        sendingQuoteRequest: state.main.sendingQuoteRequest
    };
};

const mapDispatchToProps = dispatch => {
    return {
        onSendQuoteRequest: data => dispatch(sendQuoteRequest(data))
    };
};

RequestQuote.propTypes = {
    campusRoles: PropTypes.array,
    sendingQuoteRequest: PropTypes.bool,
    onSendQuoteRequest: PropTypes.func
};

export default connect(mapStateToProps, mapDispatchToProps)(RequestQuote);
