import React from 'react';
import Helmet from 'react-helmet';

function SEO({ pageProps }) {
    return (
        <Helmet>
            <title>{pageProps.title}</title>
            <link rel="canonical" href={pageProps.url} />
            <meta name="description" content={pageProps.description} />
            <meta name="robots" content={pageProps.robots} />

            <meta property="og:title" content={pageProps.title} />
            <meta property="og:image" content="https://controlpointdismissal.com/clientapp/build/static/media/social-meta-image.png" />
            <meta property="og:url" content={pageProps.url} />
            <meta property="og:type" content="website" />
            <meta property="og:site_name" content="controlpointdismissal.com" />
            <meta property="og:description" content={pageProps.description} />


            <meta name="twitter:title" content={pageProps.title} />
            <meta name="twitter:description" content={pageProps.description}/>
            <meta name="twitter:image" content="https://controlpointdismissal.com/clientapp/build/static/media/social-meta-image.png"/>
            <meta name="twitter:site" content="controlpointdismissal.com"/>
            <meta name="twitter:creator" content="@thecontrolpoint"/>


            
        </Helmet>
    )
}

export default SEO;