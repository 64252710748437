import React from 'react';
import SEO from '../../services/SEO';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import Testimonial from  '../../components/Testimonial/Testimonial';
import './AboutUsStyles.css';

const pageProps = {
    title: "About Us - Control Point | Best student dismissal software and most effective afternoon dismissal application",
    description: "The developers of Control Point have been educators for more than 30 years and implemented the better way for dismissal | Feedback heard most often is how efficient afternoon dismissal has become since incorporating Control Point",
    url: "https://controlpointdismissal.com/support",
    robots: "index, follow"
}

function AboutUs() {
    const slickSettings = {
        dots: false,
        infinite: true,
        autoplay: true,
        speed: 250,
        slidesToShow: 3,
        slidesToScroll: 1,
        initialSlide: 0,
        responsive: [
            {
                breakpoint: 991,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }
        ]
    };

    return (
        <>
            <SEO pageProps={pageProps}/>
            <div className="page-wrapper">
          <section className="about-better-solution-section">
              <div className="border-colored"></div>
              <div className="container">
                  <div className="row">
                      <div className="col-12">
                          <h1 className="orange">A Better Solution:</h1>
                      </div>
                  </div>
                  <div className="row">
                      <div className="col-lg-7 col-md-12 col-sm-12">
                          <div className="description purple">
                              The developers of Control Point have been educators for more than 30 years.
                              Afternoon dismissal was the most frustrating part of the day. The process was outdated,
                              cumbersome and unsafe. After five years of working toward the goal of finding a "better way"
                              for dismissal, Control Point was developed. Feedback from campuses using the system has been
                              nothing short of glowing. The comment heard most often is how efficient afternoon dismissal
                              has become since incorporating Control Point.
                          </div>
                      </div>
                  </div>
              </div>
          </section>

          <section className="about-features-section">
              <div className="container">
                  <div className="row">
                      <div className="col-sm-12 col-md-4">
                          <div className="feature-container text-center">
                              <div className="feature-image">
                                  <div className="dismissal"></div>
                              </div>
                              <h3 className="orange">Dismissal Cards</h3>
                              <p className="purple">Parents or guardians create dismissal cards for themselves and any additional drivers. For student safety
                              and parent peace of mind, a dismissal card may be deleted or suspended as needed.</p>
                          </div>
                      </div>

                      <div className="col-sm-12 col-md-4">
                          <div className="feature-container text-center">
                              <div className="feature-image">
                                  <div className="process"></div>
                              </div>
                              <h3 className="orange">The Process</h3>
                              <p className="purple">Student dismissal cards are scanned by a staff member. Students remain
                                  in the classroom with their teachers. When a student's name is added to a flight,  the teacher
                                  sends the student to the designated area. The children are then moved outside and assisted into cars.</p>
                          </div>
                      </div>

                      <div className="col-sm-12 col-md-4">
                          <div className="feature-container text-center">
                              <div className="feature-image">
                                  <div className="swift"></div>
                              </div>
                              <h3 className="orange">Swift Dismissal</h3>
                              <p className="purple">Parents report waiting 5-15 minutes in the car line. Children are ready to load the car as soon as the
                              driver pulls to a stop. Parents can be confident their children will be dismissed safely and securely every day.</p>
                          </div>
                      </div>
                  </div>
              </div>
          </section>

          <section className="about-testimonials-section">
              <div className="container">
                  <Slider {...slickSettings}>
                      <Testimonial colorScheme="p-o-b"
                                   notes="Technology at its Best! Control Point is a reliable, safe and efficient way to release students to their guardians at the end of the school day."
                                   author="Y. Filiault, Administrator" />

                      <Testimonial colorScheme="o-b-p"
                                   notes="Control Point has made the car rider line much faster, and I know my daughter will be safely escorted by one of the teachers once her barcode is scanned."
                                   author="K. Copeland, Parent" />

                      <Testimonial colorScheme="b-p-o"
                                   notes="The system has increased the safety of our process for our students and families. Control Point has been a game changer for us!"
                                   author="K. Zambito, Principal" />

                      <Testimonial colorScheme="p-o-b"
                                   notes="Technology at its Best! Control Point is a reliable, safe and efficient way to release students to their guardians at the end of the school day."
                                   author="Y. Filiault, Administrator" />

                      <Testimonial colorScheme="o-b-p"
                                   notes="Control Point has made the car rider line much faster, and I know my daughter will be safely escorted by one of the teachers once her barcode is scanned."
                                   author="K. Copeland, Parent" />

                      <Testimonial colorScheme="b-p-o"
                                   notes="The system has increased the safety of our process for our students and families. Control Point has been a game changer for us!"
                                   author="K. Zambito, Principal" />
                  </Slider>

                  <div className="about-testimonials-wrapper">

                  </div>
              </div>
          </section>

            </div>
        </>
  );
}

export default AboutUs;
