import React, { Component } from 'react';
import SEO from '../../services/SEO';
import { connect } from 'react-redux';
import { NavLink } from 'react-router-dom';
import PropTypes from 'prop-types';
import { login } from '../../store/actions';

import logo from '../../assets/images/login-logo.png';
import Button from '../../UI/Button/Button';
import {LOGIN_VALIDATION} from '../../services/validation';
import utils from '../../services/utils';
import './LoginStyles.css';


const pageProps = {
    title: "Login - Control Point | Best student dismissal software and most effective afternoon dismissal application",
    description: "Login to Control Point administration panel",
    url: "https://controlpointdismissal.com/requests",
    robots: "noindex, nofollow"
}
const INITIAL_STATE = {
    fields: {
        UserName: '',
        Password: '',
        RememberMe: false
    },
    fieldsError: {},
    fieldsTouched: {}
};

class Login extends Component {
    state = {
        ...INITIAL_STATE
    };

    checkButtonStatus = fields => {
        const fieldsArray = Object.keys(fields);
        let isDisabled = false;
        for(let i=0; i< fieldsArray.length; i++) {
            const fieldName = fieldsArray[i];
            if(LOGIN_VALIDATION[fieldName] && LOGIN_VALIDATION[fieldName].required && !fields[fieldName]) {
                isDisabled = true;
            }
        }

        return isDisabled;
    };

    handleFieldChange = (e, fieldName) => {
        const {fields, fieldsTouched, fieldsError} = this.state;
        const _fields = {...fields};
        const _fieldsTouched = {...fieldsTouched};
        let value = e.target.value;
        _fieldsTouched[fieldName] = true;

        this.setState({fields: {..._fields, [fieldName]: value}, fieldsTouched: _fieldsTouched});
        utils.checkValidationErrors(fieldName, {...fields, [fieldName]: value}, _fieldsTouched, fieldsError, null, LOGIN_VALIDATION, this);
    };

    handleBlur = (fieldName) => {
        const {fields, fieldsTouched, fieldsError} = this.state;
        const _fieldsTouched = {...fieldsTouched};
        _fieldsTouched[fieldName] = true;

        this.setState({fieldsTouched: _fieldsTouched});
        utils.checkValidationErrors(fieldName, fields, _fieldsTouched, fieldsError, true, LOGIN_VALIDATION, this);
    };

    handleSubmit = () => {
        const {fields} = this.state;

        this.props.onLogin(fields);
    };

  render() {
      const {fields, fieldsError} = this.state;
      const {loading} = this.props;
      const btnDisabled = Object.keys(fieldsError).length > 0 || this.checkButtonStatus(fields) || loading;

      return (
           <>
              <SEO pageProps={pageProps} />
              <section className="login-section">
              <div className="login-form-wrapper">
                  <NavLink strict to="/" className="login-logo-container">
                          <img src={logo} width="100" alt="Control Point" title="Control Point" />
                  </NavLink>

                  <div className="form-group login-input-wrapper">
                      <label>Username</label>
                      <input type="text"
                             className={"form-control login-input " + (fieldsError.UserName ? "error" : "")}
                             onChange={e => this.handleFieldChange(e, 'UserName')}
                             onBlur={() => this.handleBlur('UserName')}
                             value={fields.UserName} />
                      {fieldsError.UserName ? <span className="cp-input-error">{fieldsError.UserName}</span> : null}
                  </div>

                  <div className="form-group login-input-wrapper">
                      <label>Password</label>
                      <input type="password"
                             className={"form-control login-input " + (fieldsError.Password ? "error" : "")}
                             onChange={e => this.handleFieldChange(e, 'Password')}
                             onBlur={() => this.handleBlur('Password')}
                             value={fields.Password} />
                      {fieldsError.Password ? <span className="cp-input-error">{fieldsError.Password}</span> : null}
                  </div>

                  <Button title="Login" active onClick={this.handleSubmit} disabled={btnDisabled} />
              </div>
              </section>
           </>
      );
  }
}

const mapStateToProps = state => {
    return {
        user: state.main.user,
        loading: state.main.loggingIn
    };
};

const mapDispatchToProps = dispatch => {
    return {
        onLogin: user => dispatch(login(user))
    };
};

Login.propTypes = {
    user: PropTypes.object,
    loading: PropTypes.bool,
    onLogin: PropTypes.func
};

export default connect(mapStateToProps, mapDispatchToProps)(Login);